body {
  background-color: #18355A;
}
.td-badge, .td-editar, .td-delete, .td-registro{
    width: 1%;
}
.react-select > div {
    margin: -1px;
}
.historial_des {
  height:150px;
  overflow-y: scroll;
}